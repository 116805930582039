import React from 'react';
import { PageLayout } from './PageLayout';

export const About = () => {
    return (
        <PageLayout title="About" header="About Me" url="https://emmettcoffin.com/about">
                <p>
                    Emmett Coffin has experience coding in modern programming languages like C#, Java, and PHP. Other skills include: MySQL, ASP.NET MVC, Android, Unity, Python, Git, HTML, CSS, JavaScript, TypeScript, React, Flutter.
                </p>
        </PageLayout>
    )
}