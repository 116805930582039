import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NavigationBar} from './components/NavigationBar';
import { Footer } from './components/Footer';
import { Home } from './pages/Home';
import { Projects } from './pages/Projects';
import { Contact } from './pages/Contact';
import { About } from './pages/About';
import { Container } from 'react-bootstrap';


function App() {
  return (
    <Container fluid className="px-0 theme-light app-container d-flex flex-column min-vh-100">
      <Router>
        <NavigationBar />
        <Container className="pt-5 mt-auto text-center flex-grow-1">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/projects" component={Projects} />
            <Route path="/contact" component={Contact} />
            <Route path="/about" component={About} />
          </Switch>
        </Container>
        <Footer />
      </Router>
    </Container>
  );
}

export default App;
