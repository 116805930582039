import React from 'react';

export const Footer = () => {
    return (
      <footer className="footer text-muted text-center">
        <span className="m-auto">
          &copy; {new Date().getFullYear()} Emmett Coffin
        </span>
      </footer>
    )
}