import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { PageLayout } from './PageLayout';
import smiley from '../images/osx-el-capitan-finder-icon.webp';

export const Home = () => {
    return (
        <PageLayout title="Home" url="https://emmettcoffin.com">
            <Image
                width="150"
                src={smiley}
                alt={"Mac OS X Smiley Face"}
            />
            <Container className="py-0 my-0">
                <h1>
                    Emmett Coffin
                </h1>
                <p>
                    Programmer. Check out what <a href="/projects">projects</a> I've worked on.
                </p>
            </Container>
        </PageLayout>
    )
}