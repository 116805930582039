import React from 'react';
import { PageLayout } from './PageLayout';
import { Project } from '../components/Project';
import styled from 'styled-components';

//import images
import bikeStore1 from '../images/project_screenshots/bike-store-1.webp';
import bikeStore2 from '../images/project_screenshots/bike-store-2.webp';
import toDoBlocks1 from '../images/project_screenshots/todoblocks-1.webp';
import toDoBlocks2 from '../images/project_screenshots/todoblocks-2.webp';
import notesApp1 from '../images/project_screenshots/notes-app-1.webp';
import notesApp2 from '../images/project_screenshots/notes-app-2.webp';
import reddit1 from '../images/project_screenshots/reddit-1.webp';
import reddit2 from '../images/project_screenshots/reddit-2.webp';
import tbsg1 from '../images/project_screenshots/tbsg-1.webp';
import tbsg2 from '../images/project_screenshots/tbsg-2.webp';
import ticTacToe1 from '../images/project_screenshots/tic-tac-toe-1.webp';
import ticTacToe2 from '../images/project_screenshots/tic-tac-toe-2.webp';

//define style project views css
const ProjectStyle = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template:
    "content content"
    "pic1 pic2";
  
    /*Change grid for small screens*/
    @media (max-width: 767px) {
        grid-template:
        "content"
        "pic1"
        "pic2";
    }

  padding: 10px 0px;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
`;

const AppStyle = styled.div`
    display: grid;
    grid-gap: 4px;
    grid-template:
      "content pic1 pic2"
      / 50% 25% 25%;

    /*Change grid for small screens*/
    @media (max-width: 767px) {
        grid-template:
        "content content"
        "pic1 pic2";
    }

    padding: 10px;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
`;

export const Projects = () => {
    const projects = [
        {
            title: "Bike Store Website",
            tech: "C#, ASP.NET MVC, HTML, CSS, JavaScript",
            desc: "Made e-commerce site for a bike store using ASP.NET MVC framework. Including a shoppping cart, user authentication, and a responsive layout. (Group Project)",
            isApp: false,
            pics: [bikeStore1, bikeStore2]
        },
        {
            title: "To-Do Blocks",
            tech: "Flutter, Dart",
            desc: "Made time scheduling app using Flutter.",
            code: "https://github.com/ecoffi/to-do-blocks",
            isApp: false,
            pics: [toDoBlocks1, toDoBlocks2]
        },
        {
            title: "Android Notes App",
            tech: "Java, Android Studio, SQLite",
            desc: "Made app to create and edit notes. Stored note data in SQLite database.",
            isApp: true,
            pics: [notesApp1, notesApp2]
        },
        {
            title: "Tic-Tac-Toe Android App",
            tech: "Java, Android Studio, SQLite",
            desc: "Made a multiplayer Tic-Tac-Toe app for Android. Tracked leaderboards and statistics about played games. (Group Project)",
            isApp: true,
            pics: [ticTacToe1, ticTacToe2]
        },
        {
            title: "Social Forum",
            tech: "PHP, MySQL, HTML, JavaScript",
            desc: "Made clone of popular Reddit social media site using MVC framework. Functionality includes user authentication, posting, and commenting.",
            isApp: false,
            pics: [reddit1, reddit2]
        },
        {
            title: "Turn-Based Strategy Game",
            tech: "C#, Unity",
            desc: "Made 3D turn-based game in the Unity engine.",
            code: "https://github.com/ecoffi/turn-based-strategy-game",
            isApp: false,
            pics: [tbsg1, tbsg2]
        }
    ];

    return (
        <PageLayout title="Projects" header="Projects" url="https://emmettcoffin.com/projects">
            {projects.map((p, index) => {
                return (
                    <div key={index} className="pb-3">
                        {/* check if project is an app and display app style */}
                        {p.isApp ? 
                        <AppStyle>
                            <Project project={p} />
                        </AppStyle> :
                        <ProjectStyle>
                            <Project project={p} />
                        </ProjectStyle>}
                    </div>
                )
            })}
        </PageLayout>
    )
}