import React from 'react';
import { Image } from 'react-bootstrap';
import styled from 'styled-components';

// view for a single project

const ContentStyle = styled.div`
  grid-area: content;
  align-self: center;
`;

const Pic1Style = styled.div`
  grid-area: pic1;
`;

const Pic2Style = styled.div`
  grid-area: pic2;
`;

export const Project = (props) => {
  const {project} = props;

  //if project has a source code property, create link
  const codeLink = project.code !== undefined ? <a href={project.code}>View Code</a>: null

  return (
    <>
      <ContentStyle>
        <h2>{project.title}</h2>
        <p>{project.desc}</p>
        Technology Used: {project.tech}
        <br />
        {codeLink}
      </ContentStyle>
      <Pic1Style>
        <Image
          width="95%"
          src={project.pics[0]}
          alt={"Screenshot of " + project.title}
        />
      </Pic1Style>
      <Pic2Style>
        <Image
          width="95%"
          src={project.pics[1]}
          alt={"Screenshot of " + project.title}
        />
      </Pic2Style>
    </>
  )
}