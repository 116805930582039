import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const Header = (props) => {
    //create header element if header title is provided
    //otherwise, return an empty fragment
    return props.header !== undefined ? <header><h1>{props.header}</h1></header> : <React.Fragment/>;
}

export const PageLayout = (props) => {
    return (
        <>
            <Helmet>
                <title>{props.title} | Emmett Coffin</title>
                <link rel="canonical" href={props.url} />
            </Helmet>
            <Header header={props.header} />
            <main>
                <Container className="pt-5 mt-5 page" fluid>
                    {props.children}
                </Container>
            </main>
        </>
    )
}