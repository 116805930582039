import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';

const NavStyle = styled.div`
    .navbar {
      background-color: #222;
    }

    a, .navbar-nav, .navbar-light .nav-link {
      color: #FFF;
      &:hover {
        color: white;
      }
    }

    .nav-link {
      &:hover {
        text-decoration: underline;
      }
    }

    .navbar-brand {
        font-size: 1.4em;
        color: #FFF;
        &:hover {
          color: white;
          text-decoration: underline
        }
    }
`;

export const NavigationBar = () => (
  <NavStyle>
    <Navbar>
      <Navbar.Brand href="/">Home</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>

      <Nav className="ml-auto">
        <Nav.Item><Nav.Link href="/projects">Projects</Nav.Link></Nav.Item>
        <Nav.Item><Nav.Link href="/contact">Contact</Nav.Link></Nav.Item>
        <Nav.Item><Nav.Link href="/about">About Me</Nav.Link></Nav.Item>
      </Nav>
    </Navbar>
  </NavStyle>
)