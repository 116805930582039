import React from 'react';
import { PageLayout } from './PageLayout';

export const socials = [
    {
        url: "https://instagram.com/emmettcoffin",
        name: "Instagram"
    },
    {
        url: "https://facebook.com/emmettcoffin.ec",
        name: "Facebook"
    },
    {
        url: "https://t.me/ecoffin",
        name: "Telegram"
    }
];

export const Contact = () => {
    return (
        <PageLayout title="Contact" header="Contact" url="https://emmettcoffin.com/contact">
            <p>
                The best way to contact me is to send me an email: contact [at] emmettcoffin [dot] com
            </p>
        </PageLayout>
    )
}